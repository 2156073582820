.loading {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.05);
}

