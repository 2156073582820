/**
 * 全局样式
 *
 * @format
 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 100px;
  height: 100%;
}
body {
  margin: 0;
  font-family: "Microsoft YaHei", helvetica neue, arial, hiragino sans gb, stheiti, wenquanyi micro hei, \5FAE\8F6F\96C5\9ED1, \5B8B\4F53, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
.ant-app {
  font-family: "Microsoft YaHei", Avenir, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
#root {
  height: 100%;
}
/*  float button */
.ant-float-btn {
  height: 50px;
  width: 50px;
}
.ant-float-btn-body {
  width: 50px !important;
  height: 50px !important;
}
.ant-float-btn-icon {
  width: auto !important;
}
.formItem_custom {
  margin-bottom: 26px;
}
.input_custom {
  font-size: 18px;
  height: 50px;
  color: #909399;
  font-family: MicrosoftYaHei;
  border-color: #dcdfe6;
}
.input_custom .ant-input::placeholder {
  color: #888f9b;
}
.input_custom .ant-input-prefix {
  margin-right: 8px;
}
.input_custom :global .anticonButton {
  cursor: pointer;
}
.input_custom:hover {
  border-color: #aacd06;
}
.input_custom:focus {
  border-color: #aacd06;
}
.input_custom.inputPassword .anticonButton {
  cursor: pointer;
}
.button_custom {
  height: 50px;
  color: #fdfffb;
  font-size: 22px;
  background: #93b927;
  font-weight: bold;
  border-radius: 6px;
  letter-spacing: -2px;
}
.custom_primary_button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  font-size: 18px;
  height: 40px;
  color: #7ca647;
  background: #f5fced;
  border: 2px solid #aacd06;
  letter-spacing: -2px;
}
.custom_primary_button.ant-btn-primary:not(.ant-btn-disabled):hover {
  background: #f0ffdd;
  color: #7ca647;
}
.ant-app {
  height: 100%;
}
.clickButton-none-outline :focus-visible {
  color: red;
  outline: 4px solid red !important;
}
.select-none {
  user-select: none;
}
.anticon:hover {
  color: #7ca647;
}
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  border-color: #aacd06;
}
.ant-btn-default:not(:disabled):hover {
  color: #fdfffb;
  background-color: #7ca647;
}
table {
  width: 100%;
  margin: 16px 0;
  border-collapse: collapse;
}
th,
td {
  border: 1px solid #999;
  text-align: center !important;
  padding: 10px 0;
}
table tbody tr:hover {
  background-color: #efefef;
}
table tfoot tr td {
  text-align: right;
  padding-right: 20px;
}
._licence .ant-modal-content {
  padding: 0;
  padding-bottom: 16px;
}
._licence .ant-modal-content .ant-modal-header {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5 !important;
}
._licence .ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 20px;
}
._licence .ant-modal-content .ant-modal-footer {
  padding-left: 16px;
  padding-right: 16px;
}
.link {
  word-wrap: break-word;
  color: #93b927;
  cursor: pointer;
}
.link:hover,
.link:active {
  color: #7ca647;
}
::-webkit-scrollbar {
  background-color: #f5f5f5;
  border-radius: 4px;
  height: 8px;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #dcdfe6;
  border-radius: 4px;
}
::-webkit-scrollbar-track {
  background-color: #ebeef5;
  border-radius: 4px;
}
::-webkit-scrollbar-button {
  display: none;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-column {
  flex-direction: column;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.font-18 {
  font-size: 18px;
  color: #606266;
}
.margin-top-10 {
  margin-top: 10px;
}
.ant-btn-link {
  color: #7ca647 !important;
  font-size: 16px;
}
.ant-btn-link:hover,
.ant-btn-link:active {
  color: #93b927 !important;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
